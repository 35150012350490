var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"service-section"},[_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('MENU.ITEM.FUELS.DATE_CHARGED'),"label-for":"item-bill-number-input"}},[_c('FormDatepicker',{attrs:{"type":"date","i18n":{
          placeholder: 'FORM_LABELS.DATE',
        },"max":_vm.today},model:{value:(_vm.charge.dateCharged),callback:function ($$v) {_vm.$set(_vm.charge, "dateCharged", $$v)},expression:"charge.dateCharged"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-measures-group","label":_vm.$t('MENU.ITEM.TANKS.VEHICLE_GAS_STATION'),"label-for":"item-measures-input"}},[_c('FormSelect',{attrs:{"clearable":"","type":"select","options":_vm.vehicles},model:{value:(_vm.charge.fuelable),callback:function ($$v) {_vm.$set(_vm.charge, "fuelable", $$v)},expression:"charge.fuelable"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-measures-group","label":_vm.$t('MENU.ITEM.STAFF.STAFF'),"label-for":"item-measures-input"}},[_c('FormSelect',{attrs:{"clearable":"","type":"select","options":_vm.selectStaffList},model:{value:(_vm.charge.staffId),callback:function ($$v) {_vm.$set(_vm.charge, "staffId", $$v)},expression:"charge.staffId"}})],1)],1),_c('b-col',{attrs:{"cols":4}},[_c('b-form-group',{attrs:{"id":"item-liters-group","label":_vm.$t('MENU.ITEM.FUELS.LITERS'),"label-for":"item-liters-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
          label: 'MENU.ITEM.FUELS.LITERS',
          placeholder: _vm.$t('MENU.ITEM.FUELS.LITERS'),
        },"validateState":{}},on:{"input":function($event){return _vm.$emit('update:qty')}},model:{value:(_vm.charge.liters),callback:function ($$v) {_vm.$set(_vm.charge, "liters", $$v)},expression:"charge.liters"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"item-mileage-group","label":_vm.$t('MENU.ITEM.FUELS.MILEAGES'),"label-for":"item-mileage-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
          label: 'MENU.ITEM.FUELS.MILEAGES',
          placeholder: _vm.$t('MENU.ITEM.FUELS.MILEAGES'),
        },"validateState":{}},model:{value:(_vm.charge.mileage),callback:function ($$v) {_vm.$set(_vm.charge, "mileage", $$v)},expression:"charge.mileage"}})],1)],1),(_vm.canRemove)?_c('b-col',{attrs:{"md":1}},[_c('b-form-group',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-danger mt-6 pr-2 pl-3",on:{"click":function($event){return _vm.$emit('remove')}}},[_c('i',{staticClass:"fa fa-trash"})])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }