<template>
  <b-row class="service-section">
    <b-col :md="6">
      <b-form-group
        :label="$t('MENU.ITEM.FUELS.DATE_CHARGED')"
        label-for="item-bill-number-input"
      >
        <FormDatepicker
          type="date"
          :i18n="{
            placeholder: 'FORM_LABELS.DATE',
          }"
          v-model="charge.dateCharged"
          :max="today"
        ></FormDatepicker>
      </b-form-group>
    </b-col>
    <b-col :md="6">
      <b-form-group
        id="item-measures-group"
        :label="$t('MENU.ITEM.TANKS.VEHICLE_GAS_STATION')"
        label-for="item-measures-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="vehicles"
          v-model="charge.fuelable"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :md="4">
      <b-form-group
        id="item-measures-group"
        :label="$t('MENU.ITEM.STAFF.STAFF')"
        label-for="item-measures-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="selectStaffList"
          v-model="charge.staffId"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :cols="4">
      <b-form-group
        id="item-liters-group"
        v-bind:label="$t('MENU.ITEM.FUELS.LITERS')"
        label-for="item-liters-input"
      >
        <FormInput
          type="number"
          :i18n="{
            label: 'MENU.ITEM.FUELS.LITERS',
            placeholder: $t('MENU.ITEM.FUELS.LITERS'),
          }"
          v-model="charge.liters"
          :validateState="{}"
          @input="$emit('update:qty')"
        ></FormInput>
      </b-form-group>
    </b-col>

    <b-col>
      <b-form-group
        id="item-mileage-group"
        v-bind:label="$t('MENU.ITEM.FUELS.MILEAGES')"
        label-for="item-mileage-input"
      >
        <FormInput
          type="number"
          :i18n="{
            label: 'MENU.ITEM.FUELS.MILEAGES',
            placeholder: $t('MENU.ITEM.FUELS.MILEAGES'),
          }"
          v-model="charge.mileage"
          :validateState="{}"
        ></FormInput>
      </b-form-group>
    </b-col>

    <b-col v-if="canRemove" :md="1">
      <b-form-group class="text-right">
        <button class="btn btn-danger mt-6 pr-2 pl-3" @click="$emit('remove')">
          <i class="fa fa-trash"></i>
        </button>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";

export default {
  name: "FuelExpensesRowCharge",
  components: {
    FormSelect,
    FormInput,
    FormDatepicker,
  },
  props: {
    canRemove: Boolean,
    charge: Object,
    staff: Array,
    vehicles: Array,
  },
  data() {
    return {
      tempStaffList: null,
      today: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    selectStaffList() {
      return this.tempStaffList || this.staff;
    },
  },
  watch: {
    "charge.fuelable": {
      immediate: true,
      handler(value) {
        this.charge.fuelableId = value.id;
        this.charge.fuelableType = value.type;
        this.charge.fuelableName = value.name;
        this.charge.isMileage = value.isMileage;
        if (
          value.type === "OwnMachineExpense" ||
          value.type === "StaffMachineExpense"
        ) {
          this.tempStaffList = null;
          this.charge.staffId = value.lastUsedStaffId || null;
        } else if (value.type === "RentedMachineExpense") {
          this.tempStaffList = [{ id: -1, name: value.staffName }];
          this.charge.staffId = -1;
        } else if (value.type === "TankExpense") {
          this.tempStaffList = [];
          this.charge.staffId = null;
        }
      },
    },
    "charge.staffId": function(value) {
      if (value !== null) {
        const selectedStaff = this.selectStaffList.find((s) => s.id === value);
        if (selectedStaff) {
          this.charge.staffName = selectedStaff.name;
        }
      } else {
        this.charge.staffName = null;
      }
    },
  },
};
</script>

<style></style>
