<template>
  <div>
    <KTCard ref="preview">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{
              name: 'edit-fuel-expenses',
              params: { id: $route.params.expenseId },
            }"
          >
            {{ fuelExpenseName }}</router-link
          >
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['tank-charges-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelExpenseRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <FuelExpensesRowForm
          v-if="!loading"
          ref="FuelExpensesRowForm"
          :fuel-expense="fuelExpense"
          :form="form"
        ></FuelExpensesRowForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['tank-charges-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelExpenseRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_FUEL_EXPENSE_ROW,
  UPDATE_FUEL_EXPENSE_ROW,
  DELETE_FUEL_EXPENSE_ROW,
} from "@/modules/fuel-expenses-row/store/fuelexpensesrow.module";
import { FETCH_FUEL_EXPENSE } from "@/modules/fuel-expenses/store/fuelexpenses.module";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import FuelExpensesRowForm from "./FuelExpensesRowForm.vue";

export default {
  name: "FuelExpensesRowEdit",
  components: {
    KTCard,
    SaveButtonDropdown,
    FuelExpensesRowForm,
  },
  data() {
    return {
      loading: true,
      errors: [],
      fuelExpense: {},
      form: {
        fuelExpenseId: null,
        fuelTypeId: null,
        price: null,
        totalPrice: "0.00",
        totalQty: 0,
        tankCharges: [],
      },
    };
  },
  computed: {
    fuelExpenseName() {
      return this.fuelExpense.invoiceNumber + " " + this.fuelExpense.gasStation;
    },
  },
  mounted() {
    this.$store
      .dispatch(FETCH_FUEL_EXPENSE, this.$route.params.expenseId)
      .then((data) => {
        this.fuelExpense = data.data;

        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: this.$i18n.t("MENU.ITEM.EXP.FUELS_EXPENSES"),
            route: { name: "list-fuel-expenses" },
          },
          {
            title: this.fuelExpenseName,
            route: {
              name: "edit-fuel-expenses",
              params: { id: this.$route.params.expenseId },
            },
          },
          { title: this.$i18n.t("LABELS.EDIT") },
        ]);
      });

    this.$store
      .dispatch(FETCH_FUEL_EXPENSE_ROW, this.$route.params.id)
      .then((data) => {
        this.form = data.data;
        this.loading = false;
      });
  },
  methods: {
    validateForm() {
      this.$refs.FuelExpensesRowForm.$v.form.$touch();
      if (this.$refs.FuelExpensesRowForm.$v.form.$anyError) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return false;
      }
      return true;
    },
    onSave(next) {
      this.errors = [];
      if (!this.validateForm()) {
        return;
      }

      const hasTankExpense = this.form.tankCharges.some(
        (tc) => tc.fuelableType === "TankExpense"
      );

      if (hasTankExpense) {
        const message =
          "Сигурнили сте, че искате да направите редакция на зареждане на цистерна/бидон? Ако има зареждания от тази цистерна след датата на създаване, е възможно да се появят проблеми при справките за горива.";
        this.$dialog
          .confirm(message)
          .then(() => {
            this.store(next);
          })
          .catch(() => {});
      } else {
        this.store(next);
      }
    },
    store(next) {
      let vm = this;

      this.form.fuelExpenseId = this.$route.params.expenseId;
      this.$store
        .dispatch(UPDATE_FUEL_EXPENSE_ROW, {
          id: this.$route.params.id,
          payload: this.form,
        })
        .then((data) => {
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-fuel-expenses-row",
              params: { expenseId: this.form.fuelExpenseId, id: itemEdit.id },
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-fuel-expenses-row" });
          }
          if (next == "exit") {
            this.$router.push({
              name: "edit-fuel-expenses",
              params: { id: this.form.fuelExpenseId },
            });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    onDelete() {
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.$store
          .dispatch(DELETE_FUEL_EXPENSE_ROW, this.$route.params.id)
          .then((data) => {
            this.$notify({
              group: "notify",
              type: "success",
              title: "<i class='flaticon2-checkmark'></i> Success",
              text: data.message,
            });
            this.$router.push({
              name: "edit-fuel-expenses",
              params: { id: this.form.fuelExpenseId },
            });
          })
          .catch((response) => {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$t("ALERTS.ERROR"),
              text: response.data.message,
            });
          });
      });
    },
  },
};
</script>
