<template>
  <v-card flat>
    <b-row>
      <b-col :md="6">
        <b-form-group
          id="item-fuel-types-group"
          v-bind:label="$t('MENU.ITEM.FUELS.FUEL_TYPE')"
          label-for="item-fuel-types-input"
        >
          <FormSelect
            id="item-fuel-types-input"
            type="select"
            clearable
            :options="fuelTypes"
            :validateState="validateState('fuelTypeId')"
            v-model="form.fuelTypeId"
          ></FormSelect>
        </b-form-group>
      </b-col>
      <b-col :md="6">
        <b-form-group
          id="item-price-group"
          v-bind:label="$t('FORM_LABELS.PRICE')"
          label-for="item-price-input"
        >
          <FormInput
            type="number"
            :i18n="{
              label: 'FORM_LABELS.PRICE',
              placeholder: $t('FORM_LABELS.PRICE'),
            }"
            v-model="form.price"
            :validations="'validations: {required: true, minLength: 0}'"
            :validateState="validateState('price')"
            @input="totalSum"
          ></FormInput>
        </b-form-group>
      </b-col>
      <!-- <b-col :md="6">
        <b-form-group
          id="invoice-start-date"
          :label="$t('FORM_LABELS.FROM')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-included-date-from"
            type="text"
            name="inv_date_from"
            :i18n="{ label: '', placeholder: '' }"
            :validations="'validations: {required: true, minLength: 0}'"
            :validateState="validateState('dateFrom')"
            max-today
            v-model="form.dateFrom"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
      <b-col :md="6">
        <b-form-group
          id="item-bill-number-group"
          :label="$t('FORM_LABELS.TO')"
          label-for="item-bill-number-input"
        >
          <FormDatepicker
            id="invoice-included-date-to"
            type="date"
            name="inv_date_to"
            :i18n="{ label: '', placeholder: '' }"
            :validations="'validations: {required: false, minLength: 0}'"
            :validateState="validateState('dateTo')"
            max-today
            v-model="form.dateTo"
          ></FormDatepicker>
        </b-form-group>
      </b-col> -->
    </b-row>
    <div v-if="form.tankCharges.length > 0" class="text-right p-5">
      <h5>
        <span>
          Общо к-во: <b>{{ form.totalQty.toFixed(3) }} л.</b>
        </span>
        <span class="text-success">
          Обща сума: <b>{{ form.totalPrice.toFixed(4) }} лв.</b>
        </span>
      </h5>
    </div>
    <b-row>
      <div class="col-md-4 d-flex align-center">
        <h3>Зареждания ({{ form.tankCharges.length }})</h3>
      </div>
      <div class="col-md-8 text-right">
        <button class="btn btn-success" @click="handleAddCharge">
          <strong>+ Добави зареждане</strong>
        </button>
      </div>
    </b-row>

    <FuelExpensesRowCharge
      class="service-section"
      v-for="(item, i) in form.tankCharges"
      :key="i"
      :charge="item"
      :staff="staffList"
      :vehicles="vehicles"
      :can-remove="i > 0"
      @remove="handleRemove(i)"
      @update:qty="handleUpdateQty"
    >
    </FuelExpensesRowCharge>
    <b-row>
      <div class="col-md-8 d-flex align-center">
        <div v-if="form.tankCharges.length > 0" class="text-left">
          <h5>
            <span>
              Общо к-во: <b>{{ form.totalQty.toFixed(3) }} л.</b>
            </span>
            <span class="text-success">
              Обща сума: <b>{{ form.totalPrice.toFixed(4) }} лв.</b>
            </span>
          </h5>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <button class="btn btn-success" @click="handleAddCharge">
          <strong>+ Добави зареждане</strong>
        </button>
      </div>
    </b-row>
  </v-card>
</template>

<script>
import { FETCH_FUELS_TYPES } from "@/modules/fuels-types/store/fuel-type.module";
import { FETCH_MACHINE_AND_TANK_OPTIONS } from "@/modules/tank-charges/store/tankcharges.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FuelExpensesRowCharge from "./FuelExpensesRowCharge";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

export default {
  name: "FuelExpensesForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    FormSwitch,
    FormDatepicker,
    FuelExpensesRowCharge,
  },
  props: {
    form: Object,
    fuelExpense: Object,
  },
  validations() {
    const tmpValidationObject = {
      form: {
        price: this.setItemValidations({
          required: true,
        }),
        fuelTypeId: this.setItemValidations({
          required: true,
        }),
      },
    };

    return tmpValidationObject;
  },
  data() {
    return {
      tankChargesResults: [],
      tankChargesLoading: false,
      options: [],
      fuelTypes: [],
      vehicles: [],
      staffList: [],
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_MACHINE_AND_TANK_OPTIONS).then((data) => {
      this.vehicles = data.data.map((v) => {
        return {
          id: {
            id: v.id,
            type: v.type,
            name: v.name,
            staffName: v.staffName,
            lastUsedStaffId: v.lastUsedStaffId,
            isMileage: v.isMileage,
            noMileage: v.noMileage
          },
          name: v.name,
        };
      });
    });

    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then((data) => {
      let items = data.data.items;

      this.staffList = items || [];
    });

    this.$store.dispatch(FETCH_FUELS_TYPES).then((data) => {
      this.fuelTypes = data.data.items;
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    totalSum() {
      const total = parseFloat(this.form.price) * this.form.totalQty || 0;

      this.form.totalPrice = total;
    },
    handleUpdateQty() {
      this.form.totalQty = 0;
      this.form.tankCharges.forEach((tc) => {
        this.form.totalQty += parseFloat(tc.liters || 0);
      });

      this.totalSum();
    },
    handleRemove(i) {
      this.form.tankCharges.splice(i, 1);
      this.handleUpdateQty();
    },
    handleAddCharge() {
      const lastCharge = {
        dateCharged: null,
        fuelableId: null,
        fuelableType: null,
        fuelableName: null,
        staffId: null,
        staffName: null,
        liters: null,
        mileage: null,
      };
      this.form.tankCharges.push(lastCharge);
      this.handleUpdateQty();
    },
  },
};
</script>

<style type="text/css">
.input-lg input {
  font-size: 14px;
  font-weight: bold;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
#item-bol-list-group label {
  font-weight: bold;
  font-size: 15px;
}

.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

.service-section {
  border: 1px solid #ecf0f3;
  margin-bottom: 5px !important;
  border-radius: 0.42rem;
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}
</style>
